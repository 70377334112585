<template>
  <v-container>
    <v-row>
      <v-col
        cols="12"
        md="10"
        lg="8"
        xl="6"
      >
        <v-card>
          <v-card-text>
            <v-container>
              <label
                class="description d-block"
              >
                Coaching Languages
              </label>
              <coaching-languages
                v-model="coachingLanguages"
                :items="allLanguages"
                label="Select a language"
                class="mt-4 mb-2"
              />
              <app-button
                :loading="loadingButton"
                @click="save"
              >
                Save
              </app-button>
            </v-container>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex';
import allLanguages from '@kickbox/common-util/constants/languages';
import companyService from '@/services/companyService';
import CoachingLanguages from '@/components/projects/CoachingLanguages';

export default {
  name: 'SettingsCoachingLanguages',
  components: {
    CoachingLanguages
  },
  data() {
    return {
      allLanguages,
      coachingLanguages: [],
      loadingButton: false
    };
  },
  computed: {
    ...mapGetters([
      'company'
    ])
  },
  created() {
    this.coachingLanguages = this.company.coachingLanguages;
  },
  methods: {
    ...mapMutations([
      'updateCompany'
    ]),
    async save() {
      this.loadingButton = true;
      const coachingLanguages = await companyService.setCoachingLanguages(this.coachingLanguages);
      this.$store.dispatch('showSnackBar', { text: 'The coaching languages were saved.' });
      this.updateCompany({
        ...this.company,
        coachingLanguages
      });
      this.loadingButton = false;
      return true;
    }
  }
};
</script>

<style scoped>
  label {
    color: rgba(0, 0, 0, .54);
    font-size: 12px;
  }

  label.description {
    font-size: 14px;
  }
  ::v-deep .v-select__selections {
    margin-top: 0.5rem;
  }
</style>
