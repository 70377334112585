<template>
  <v-autocomplete
    v-if="allItems.length"
    ref="autocomplete"
    v-model="selectedItems"
    :items="allItems"
    :label="label"
    chips
    item-text="name"
    item-value="key"
    multiple
    return-object
    clearable
  >
    <template #selection="data">
      <v-chip
        input-value="data.selected"
        close
        pill
        class="chip--select-multi pl-0"
        @click:close="removeItem(data.item)"
      >
        <span class="pl-2">
          {{ data.item.name }}
        </span>
      </v-chip>
    </template>
  </v-autocomplete>
</template>

<script>
export default {
  name: 'CoachingLanguages',
  props: {
    value: {
      default: () => ([])
    },
    label: {
      type: String,
      default: 'Preferred Coaching Language'
    },
    items: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      allItems: [],
      selectedItems: []
    };
  },
  watch: {
    selectedItems() {
      this.$emit('input', this.selectedItems);
    }
  },
  async created() {
    if (!this.items.length) {
      this.allItems = this.$store.getters.company.coachingLanguages;
    } else {
      this.allItems = this.items;
    }
    this.selectedItems = this.value;
  },
  methods: {
    removeItem(item) {
      this.selectedItems = this.selectedItems.filter((i) => i.key !== item.key);
    }
  }
};
</script>

<style scoped>
::v-deep .v-select__selections {
  margin-top: 0.5rem;
}
</style>
